<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_' + MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{
          this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.product.name_' + MY_CONSTANT.sqlWhereLang)
        }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" id="register_form">
          <el-form-item :label="this.$MyConstant('Lang.CProduct_title')+'：'" prop="title">
            <!--                        '[^\\u4e00-\\u9fa5]': '',-->
            <el-input class="input400" @keyup.native="strReplace('title',{' ': ''})" v-model="ruleForm.title"
                      :placeholder="this.$MyConstant('Lang.exampleCProduct_title')" onkeyup=""></el-input>
          </el-form-item>
          <el-form-item :label="MY_CONSTANT.Lang.EProduct_title+'：'" prop="title_en">
            <el-input class="input400" v-model="ruleForm.title_en"
                      @keyup.native="strReplace('title_en',{'[\\u4E00-\\u9FA5]': ''})"
                      :placeholder="this.$MyConstant('Lang.exampleEProduct_title')"></el-input>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.ProductImg+'：'" prop="img">
            <el-button size="large" @click="uploadPicture"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                class="uploadText">{{ this.$MyConstant('Lang.uploadFile') }}</span></el-button>
            <div style="color:#999" class=" img">
              {{ this.$MyConstant('Lang.LogoUploadDesc') +MY_CONSTANT.Upload.product.imgSize+ 'M,' + MY_CONSTANT.Upload.product.imgWidth+ '*' + this.$MyConstant('Upload.logo.height') }}
            </div>
            <img style="width: 200px" v-show="ruleForm.img" :src="ruleForm.img" class="avatar">
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.product_type')+'：'"
                        prop="product_type" class="Linheight28">
            <el-checkbox-group v-model="ruleForm.product_type" style="">
              <el-checkbox class="myRedCheckBox"  v-for="product_types in product_types"
                           :label="product_types" name="product_type" :key="product_types">{{ product_types }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.service')+'：'"
                        prop="service" class="Linheight28">
            <el-checkbox-group v-model="ruleForm.service" style="">
              <el-checkbox class="myRedCheckBox"  v-for="services in services"
                           :label="services" name="service" :key="services">{{ services }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.CProduct_model+'：'" prop="model">
            <el-input class="input307" v-model="ruleForm.model"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>

          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.CProduct_size+'：'" prop="size">
            <el-input class="input307" v-model="ruleForm.size"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>

          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.CProduct_texture+'：'" prop="texture">
            <el-input class="input307" v-model="ruleForm.texture"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>

          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.CProduct_color+'：'" prop="color">
            <el-input class="input307" v-model="ruleForm.color"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.Product_custom')+'：'" prop="custom">
            <el-select v-if="this.$MyConstant('sqlWhereLang')=='cn'" size="large" class="select400" v-model="ruleForm.custom" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Product_custom')" >
              <el-option label="是"  value="1"></el-option>
              <el-option label="否"   value="0"> </el-option>
            </el-select>
            <el-select v-if="this.$MyConstant('sqlWhereLang')=='en'" size="large"  class="select400" v-model="ruleForm.custom" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Product_custom')" >
              <el-option label="Yes"  value="1"></el-option>
              <el-option label="No"   value="0"> </el-option>
            </el-select>

          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.CProduct_introduce')+'：'" prop="introduce" class="Linheight28">
            <el-input type="textarea" v-model="ruleForm.introduce" @keyup="strReplace('introduce',{'\\s+': ' ','\\n':''})"  :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')"  ></el-input>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.EProduct_introduce')+'：'" prop="introduce_en" class="Linheight28">
            <el-input type="textarea" v-model="ruleForm.introduce_en" @keyup="strReplace('introduce_en',{'[\\u4e00-\\u9fa5]': '','\\n':' ','\\s+':' ','\/+':'/','\\\\':'','#':''})"  :placeholder="this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Conten')"  ></el-input>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.Product_information')+'：'" prop="information" class="Linheight28">
            <el-upload
                v-model:file-list="ruleForm.information"
                class="upload-demo"
                :action="MY_CONSTANT.Api_CONSTANT.baseUrl+'/api/upload/a'"
                :headers="MY_CONSTANT.Api_CONSTANT.baseApiHeaders"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-exceed="handleExceed"
                multiple
                :limit="5"
                :on-remove="handleRemove"
            >
              <el-button size="large"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                  class="uploadText">{{ MY_CONSTANT.Lang.uploadFile }}</span></el-button>
              <template #tip>
                <div class="el-upload__tip" v-if="MY_CONSTANT.sqlWhereLang=='cn'" >
                  支持上传常规文件类型,最多上传{{ MY_CONSTANT.Upload.product.informationCount }}个， 单个大小不超过{{ MY_CONSTANT.Upload.product.informationSize }}M
                </div>
                <div class="el-upload__tip" v-else>
                  You can upload a maximum of {{ MY_CONSTANT.Upload.product.informationCount }} regular files, each size not exceeding {{ MY_CONSTANT.Upload.product.informationSize }}M
                </div>

              </template>
            </el-upload>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.sort')+'：'" prop="sort"    >
            <el-input-number style="width: 150px" v-model="ruleForm.sort" :min="0" :max="100"></el-input-number><br>
            <p style="display: contents;font-size: 12px;font-weight: normal;line-height: normal;letter-spacing: 0em;color: rgba(0, 0, 0, 0.4);">{{this.$MyConstant('Lang.sortDesc')}}</p>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.status+'：'" prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio label="1">{{MY_CONSTANT.Lang.enabled}}</el-radio>
              <el-radio label="0">{{MY_CONSTANT.Lang.disabled}}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="'\n'">
            <el-button :disabled="submitFormTrue"  type="danger"
                       @click="submitForm('ruleForm')">{{ MY_CONSTANT.Lang.submit }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition.list">
          <custom-link :style="{'color': 'product' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_' + MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>
        <Components :datas='{
        "Template/About-right": "",
         }'>
        </Components>
      </div>
    </div>

  </div>

  <!-- 剪裁组件弹窗 -->
  <el-dialog
      :title="MY_CONSTANT.Lang.Upload+MY_CONSTANT.Lang.ProductImg"
      v-model="cropperModel"
      width="100%"
      style="max-width: 1000px;"
      center
  >

    <upload-cropper
        @uploadImgSuccess="handleUploadSuccess"
        :cropWidth="MY_CONSTANT.Upload.product.imgWidth"
        :cropHeight="MY_CONSTANT.Upload.product.imgHeight"
        ref="child">
    </upload-cropper>
  </el-dialog>
</template>

<script>
import Components from '../../../modules/Components.vue'
import validate, {validateWorldMobile, validateUser, validateSelect} from "@/assets/js/validation";
import api from "@/assets/js/api";
import uploadCropper from "./VueCropper";
import {decryptString} from "@/assets/js/globalFunctions";

export default {
  components: {
    Components,
    uploadCropper
  },
  data() {
    return {
      submitFormTrue: false,
      product_types: [],
      services: [],
      cropperModel: false,
      ruleForm: {
        title: '',
        title_en: '',
        product_type: [],
        service: [],
        img: '',
        model: '',
        size: '',
        texture: '',
        color: '',
        custom:'0',
        introduce: '',
        introduce_en: '',
        information: [],
        sort: 0,
        status: '1'
      },
      rules: {
        title: [
          {
            required: this.$MyConstant('sqlWhereLang') == 'cn',
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CProduct_title'),
            trigger: 'blur'
          },
        ],
        title_en: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.EProduct_title'),
            trigger: 'blur'
          },
        ],
        product_type: [
          {
            type: 'array',
            required: true,
            message: () => this.$MyConstant('Lang.PleaseSelectOne') + this.$MyConstant('Lang.product_type'),
            trigger: ['change', 'blur']
          },
          {validator: validate.validateSelect, trigger: 'blur'},
        ],
        service: [
          {
            type: 'array',
            required: true,
            message: () => this.$MyConstant('Lang.PleaseSelectOne') + this.$MyConstant('Lang.service'),
            trigger: ['change', 'blur']
          },
          {validator: validate.validateSelect, trigger: 'blur'},
        ],
        img: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.uploadFile') + this.$MyConstant('Lang.ProductImg'),
            trigger: 'blur'
          },
        ],
        model: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CProduct_model'),
            trigger: 'blur'
          },
        ],
        size: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CProduct_size'),
            trigger: 'blur'
          },
        ],
        texture: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CProduct_texture'),
            trigger: 'blur'
          },
        ],
        color: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CProduct_color'),
            trigger: 'blur'
          },
        ],
       custom: [

        { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.listedOrNot'), trigger: ['change','blur'] }

      ],introduce: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validLength.bind(250), trigger: ['change','blur']},
          { validator: validate.validateCn, trigger: ['change','blur'] },
        ],introduce_en: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter'), trigger: 'blur'},
          { validator: validate.validateEng, trigger: ['change','blur'] },
        ]

      }
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      async handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.jiazaiArr();
      }
    }
  },
  async created() {

  },
  methods: {
    async jiazaiArr() {
      const response = await api.ajaxbaseUrlget('/api/User/userInfo')
      if(response.data.obj.data.submitted==0){
        this.$message.error({
          message: '请到个人中心提交完整资料才能进行操作',
          offset: 400, duration: 3000
        });
        setTimeout(()=>{
        this.$router.push('/'+this.MY_CONSTANT.setApiLang+'/UserEdit');
        },3000)
        return;
      }
      this.product_types = response.data.obj.data.product_type;
      this.services = response.data.obj.data.service;
      //如果id存在
      if (this.$route.query.id) {
        const response = await api.ajaxbaseUrlget('/api/Product/s?UserSelect=true&id=' + this.$decryptString(this.$route.query.id))

        //字符串转化json
        response.data.obj.data[0]['information'] = !this.$empty(response.data.obj.data[0].information)?JSON.parse(response.data.obj.data[0].information):[];
        //转字符串
        response.data.obj.data[0]['custom']= response.data.obj.data[0].custom.toString();
        response.data.obj.data[0]['status']= response.data.obj.data[0].status.toString();
        // console.log(response.data.obj.data[0]['information']);
        //若不为空字符串转化数组
       response.data.obj.data[0]['product_type'] = !this.$empty(response.data.obj.data[0].product_type)?response.data.obj.data[0].product_type.split(','):[];
       response.data.obj.data[0]['service'] = !this.$empty(response.data.obj.data[0].service)?response.data.obj.data[0].service.split(','):[];


        this.ruleForm = response.data.obj.data[0];
      }

    }
    ,
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10; // 10MB
      if (!isLt10M) {
        this.$message.error('File size must be less than 10MB!');
      }
      return isLt10M;
    },
    handleSuccess(response, file, fileList) {
      if (response && response.obj.data.url) {
        const newFile = {
          name: file.name,
          url: response.obj.data.url,
          size: file.size,
        };
        this.ruleForm.information = [...this.ruleForm.information.slice(0, -1), newFile];

      } else {
        this.$message.error('Invalid server response format');
      }
    },
    handleExceed(files, fileList) {

      this.$message.warning(`The limit is 5 files. You selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    strReplace(v,replacements='{}') {
      setTimeout(() => {

        // 要进行替换的原始字符串
        var originalString = this.ruleForm[v];

        // 使用 replace() 函数进行多字符串替换
        for (var key in replacements) {
          if (replacements.hasOwnProperty(key)) {
            originalString = originalString.replace(new RegExp(key, "g"), replacements[key]);

          }
        }

        //判断英文第一个是否存在空格
        var jiancha=originalString.charAt(0);
        if(jiancha==' ') {
          originalString= originalString.replace(jiancha,'');
        }

        this.ruleForm[v]=originalString;

      }, 1000);

    },
    //封面设置
    uploadPicture() {
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess(data) {

      this.ruleForm.img = data;

      this.cropperModel = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormTrue = true;
          api.ajaxbaseUrl.post('/api/Product/Au', this.ruleForm, {headers: {'Accept-Language': this.MY_CONSTANT.setApiLang}})
              .then(response => {

                this.$message.success({
                  message: response.data.msg,
                  offset: 400, duration: 3000
                });
                setTimeout(() => {
                  this.$router.push('/'+this.MY_CONSTANT.setApiLang+'/UserProductDo')
                }, 1000);

              });
        } else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })

          return false;
        }
      });

    },


  }
};
</script>

<style scoped>
/deep/ .quantity .el-input__wrapper {
  width: 50px;

}
</style>
