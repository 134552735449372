<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_' + MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.product.name_' + MY_CONSTANT.sqlWhereLang) }}</em>
    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <el-radio-group>
          <el-radio-button :value="MY_CONSTANT.Lang.Add"><custom-link style="color:white" :to="'/UserProductAu'">{{ MY_CONSTANT.Lang.Add }}</custom-link></el-radio-button>
        </el-radio-group>

        <el-table  :data="filterTableData" style="width: 100%">
          <el-table-column  :label="MY_CONSTANT.Lang.CProduct_title" prop="title"/>
          <el-table-column  :label="MY_CONSTANT.Lang.EProduct_title" prop="title_en"/>
          <el-table-column :label="MY_CONSTANT.Lang.ProductImg" width="180">
            <template #default="scope">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.img"
                  @click="showImgDialog(scope.row.img)"
                  lazy
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column  :label="MY_CONSTANT.Lang.sort" sortable prop="sort"/>


          <el-table-column align="right">
            <template #header>
              <el-input v-model="search" style="width: 100%;" size="small" :placeholder="MY_CONSTANT.Lang.search"/>
            </template>
            <template #default="scope">
              <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                {{ MY_CONSTANT.Lang.Edit }}
              </el-button>
              <el-button
                  size="small"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
              >
                {{ MY_CONSTANT.Lang.Delete }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 图片预览对话框 -->
        <el-dialog
            title="查看产品图"
            v-model="imgDialogVisible"
            center>
          <img :src="dialogImageUrl" v-if="imgDialogVisible" style="width: 100%" alt="查看">
        </el-dialog>

      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'product' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_' + MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas='{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>
</template>
<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
import CustomLink from "@/modules/CustomLink";

export default {
  components: {
    CustomLink,
    Components
  },
  computed: {
    filterTableData() {
      // 确保 tableData 是一个数组，并且不为 null 或 undefined
      const data = this.tableData || [];
      return data.filter(
          (item) =>
              !this.search ||
              (item.title && item.title.toLowerCase().includes(this.search.toLowerCase())) ||
              (item.title_en && item.title_en.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
  data() {
    return {
      search: '',
      tableData: [],
      dialogImageUrl: '', // 存储要显示的大图链接
      imgDialogVisible: false, // 控制对话框的显示状态
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      async handler() {

      }
    }
  },
  async created() {
    this.tableData = await api.ajaxbaseUrlData('/api/Product/s',
        {'UserSelect':true,order:JSON.stringify({id:'desc'})}
    )
  },
  methods: {
    handleDelete(index, row) {
      //询问用户是否确认删除
      this.$confirm(this.MY_CONSTANT.Lang.DeletePrompt, this.MY_CONSTANT.Lang.Tips, {
        confirmButtonText: this.MY_CONSTANT.Lang.Confirm,
        cancelButtonText: this.MY_CONSTANT.Lang.Cancel,
        type: 'warning'
      }).then(() => {
        api.ajaxbaseUrlData('/api/Product/d',{'id':row.id})
        this.tableData.splice(index, 1);
        this.$message({
          type: 'success',
          message: this.MY_CONSTANT.Lang.deleteSuccess
        });
      }).catch(() => {});
    },
    handleEdit(index, row) {
      this.$router.push({
        path: '/'+this.MY_CONSTANT.setApiLang+'/UserProductAu',
        query: {id: this.$encryptString(row.id)}
      });
    },
    showImgDialog(url) {
      this.dialogImageUrl = url;
      this.imgDialogVisible = true;
    }
  }
};
</script>

<style scoped>
/deep/ .quantity .el-input__wrapper {
  width: 50px;

}

</style>
