<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_' + MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{
          this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.video.name_' + MY_CONSTANT.sqlWhereLang)
        }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" id="register_form">

          <el-form-item :label="this.$MyConstant('Lang.Video_cat_id')+'：'" prop="cat_id">
            <el-select style="width: 400px;" v-if="this.$MyConstant('sqlWhereLang')=='cn'" size="large" class="select400" v-model="ruleForm.cat_id" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id')" >
              <el-option label="大会主论坛"  value="100"></el-option>
              <el-option label="平行论坛"   value="101"> </el-option>
            </el-select>
            <el-select style="width: 400px;" v-if="this.$MyConstant('sqlWhereLang')=='en'" size="large"  class="select400" v-model="ruleForm.cat_id" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id')" >
              <el-option label="Main Forum"  value="100"></el-option>
              <el-option label="Parallel forum"   value="101"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.CVideo_title')+'：'" prop="title">
            <!--                        '[^\\u4e00-\\u9fa5]': '',-->
            <el-input class="input400" @keyup.native="strReplace('title',{' ': ''})" v-model="ruleForm.title"
                      :placeholder="this.$MyConstant('Lang.exampleCVideo_title')" onkeyup=""></el-input>
          </el-form-item>
          <el-form-item :label="MY_CONSTANT.Lang.EVideo_title+'：'" prop="title_en">
            <el-input class="input400" v-model="ruleForm.title_en"
                      @keyup.native="strReplace('title_en',{'[\\u4E00-\\u9FA5]': ''})"
                      :placeholder="this.$MyConstant('Lang.exampleEVideo_title')"></el-input>
          </el-form-item>


          <el-form-item v-if="ruleForm.url.length > 0||VideoBlob" :label="MY_CONSTANT.Lang.VideoImg+'：'" prop="img">
            <el-button size="large" @click="uploadPictureVideo"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                class="uploadText">{{ MY_CONSTANT.Lang.Set+MY_CONSTANT.Lang.VideoImg }}</span></el-button>
            <div style="color:#999" class=" img">
              {{ this.$MyConstant('Lang.LogoUploadDesc') + this.$MyConstant('Upload.video.coverImgSize') + 'M,' + this.$MyConstant('Upload.video.coverImgWidth') + '*' + this.$MyConstant('Upload.video.coverImgHeight') }}
            </div>
            <img style="width: 200px" v-show="ruleForm.img" :src="ruleForm.img" class="avatar">
          </el-form-item>



          <el-form-item :label="this.$MyConstant('Lang.Video_url')+'：'" prop="url" class="Linheight28">
            <el-upload
                ref="upload"
                :file-list="ruleForm.url"
                class="upload-demo"
                :accept="MY_CONSTANT.Upload.video.videoType"
                :action="MY_CONSTANT.Api_CONSTANT.baseUrl+'/api/upload/a'"
                :headers="MY_CONSTANT.Api_CONSTANT.baseApiHeaders"
                :on-success="handleUploadSuccess"


                @change="handleVideoChange"


                :auto-upload="false"

            >
<!--
 multiple
     :before-upload="beforeUpload"
 :on-remove="handleRemove"

  :limit="1"
   :on-exceed="handleExceed"
-->
              <el-button size="large"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                  class="uploadText">{{ MY_CONSTANT.Lang.uploadFile }}</span></el-button>
              <template #tip>
                <div class="el-upload__tip">
                  {{ MY_CONSTANT.Lang.Video_tip }}{{ MY_CONSTANT.Upload.video.videoSize }}M
                </div>
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.sort')+'：'" prop="sort"     >
            <el-input-number style="width: 150px" v-model="ruleForm.sort" :min="0" :max="100"></el-input-number><br>
            <p style="display: contents;font-size: 12px;font-weight: normal;line-height: normal;letter-spacing: 0em;color: rgba(0, 0, 0, 0.4);">{{this.$MyConstant('Lang.sortDesc')}}</p>
          </el-form-item>

          <el-form-item :label="MY_CONSTANT.Lang.status+'：'" prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio label="1">{{MY_CONSTANT.Lang.enabled}}</el-radio>
              <el-radio label="0">{{MY_CONSTANT.Lang.disabled}}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="'\n'">
            <el-button :disabled="uploadInProgress"  type="danger"
                       @click="submitForm('ruleForm')">{{ uploadInProgress ? '上传中...' : MY_CONSTANT.Lang.submit  }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'video' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_' + MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>
        <Components :datas='{
        "Template/About-right": "",
         }'>
        </Components>
      </div>
    </div>

  </div>



  <!-- 剪裁视频封面 -->
  <videoCover
      :isShow="videoForm.comIsShow"
      :file="videoForm.file"
      @closeDialog="close"
      @confirmImg="confirmImg"
  ></videoCover>

  <!-- 全局等待状态指示器 -->
  <el-dialog
      title="正在上传"
      v-model="uploadInProgress"
      width="30%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
  >
    <span>请勿关闭或刷新页面，上传完成后将自动关闭此对话框。</span>
    <span slot="footer" class="dialog-footer">
        <el-progress :percentage="uploadProgress" status="success"></el-progress>
      </span>
  </el-dialog>

</template>

<script>
import Components from '../../../modules/Components.vue'
import validate, {validateWorldMobile, validateUser, validateSelect} from "@/assets/js/validation";
import api from "@/assets/js/api";
import videoCover from "./VueCropperVideo";
import {decryptString} from "@/assets/js/globalFunctions";
import { ref } from 'vue';
export default {
  components: {
    Components,
    videoCover
  },
  setup() {
    const uploadInProgress = ref(false);
    const uploadComplete = ref(false);
    const uploadProgress = ref(99);
    return {
      uploadInProgress,
      uploadComplete,
      uploadProgress
    };
  },
  data() {
    return {
      VideoBlob: false,
      videoForm: {
        file: {},
        comIsShow: false,
      },
      imgLookUrl: "",
      ruleForm: {
        cat_id:'100',
        title: '',
        title_en: '',
        img: '',
        url: [],
        sort:0,
        status: '1'
      },
      rules: {
      cat_id: [

        { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id'), trigger: ['change','blur'] }

      ],
        title: [
          {
            required: this.$MyConstant('sqlWhereLang') == 'cn',
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CVideo_title'),
            trigger: 'blur'
          },
        ],
        title_en: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.EVideo_title'),
            trigger: 'blur'
          },
        ],
        // img: [
        //   {
        //     required: true,
        //     message: () => this.$MyConstant('Lang.uploadFile') + this.$MyConstant('Lang.VideoImg'),
        //     trigger: 'blur'
        //   },
        // ],
        url: [
          {required: true, message: () => this.$MyConstant('Lang.uploadFile') + this.$MyConstant('Lang.Video_url'), trigger: 'blur'},
        ],

      }
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      async handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.jiazaiArr();
      }
    }
  },
  async created() {

  },
  methods: {

    close() {
      this.videoForm.comIsShow = false;
    },
    confirmImg(data) {
      this.imgLookUrl = data.url;

    },
    async jiazaiArr() {
      const response = await api.ajaxbaseUrlget('/api/User/userInfo')
      if(response.data.obj.data.submitted==0){
        this.$message.error({
          message: '请到个人中心提交完整资料才能进行操作',
          offset: 400, duration: 3000
        });
        setTimeout(()=>{
          this.$router.push('/'+this.MY_CONSTANT.setApiLang+'/UserEdit');
        },3000)

      }
      //如果id存在
      if (this.$route.query.id) {
        const response = await api.ajaxbaseUrlget('/api/Video/s?UserSelect=true&id=' + this.$decryptString(this.$route.query.id))
        //字符串转化json
        response.data.obj.data[0]['url'] = !this.$empty(response.data.obj.data[0].url)?JSON.parse(response.data.obj.data[0].url):[];
        //转字符串
        response.data.obj.data[0]['cat_id']= response.data.obj.data[0].cat_id.toString();
        response.data.obj.data[0]['status']= response.data.obj.data[0].status.toString();
        this.ruleForm = response.data.obj.data[0];
      }
    }
    ,
    beforeUpload(file) {

      const isMax = file.size / 1024 / 1024 < this.MY_CONSTANT.Upload.video.videoSize; // 10MB
      if (!isMax) {
        this.$message.error('File size must be less than '+this.MY_CONSTANT.Upload.video.videoSize+'MB!');
      }
      return isMax;
    },
    //视频上传前触发事件
    handleVideoChange(file) {

      this.$refs.upload.clearFiles();
      const isMax = file.size / 1024 / 1024 < this.MY_CONSTANT.Upload.video.videoSize; // 10MB
      if (!isMax) {
        this.$message.error('File size must be less than '+this.MY_CONSTANT.Upload.video.videoSize+'MB!');return;
      }
      this.ruleForm.url = [file];
      //视频是blob类型
      this.VideoBlob=file.raw;
      this.$refs.ruleForm.clearValidate('url'); // 只清除 'url' 字段的验证
    },
    handleExceed(files, fileList) {

      this.$message.warning(`只能上传${files.length}个视频`);
    },
    handleRemove(file, fileList) {

      // console.log(file, fileList);
    },
    strReplace(v,replacements='{}') {
      setTimeout(() => {

        // 要进行替换的原始字符串
        var originalString = this.ruleForm[v];

        // 使用 replace() 函数进行多字符串替换
        for (var key in replacements) {
          if (replacements.hasOwnProperty(key)) {
            originalString = originalString.replace(new RegExp(key, "g"), replacements[key]);

          }
        }

        //判断英文第一个是否存在空格
        var jiancha=originalString.charAt(0);
        if(jiancha==' ') {
          originalString= originalString.replace(jiancha,'');
        }

        this.ruleForm[v]=originalString;

      }, 1000);

    },

    confirmImg(data) {
      this.ruleForm.img = data.url;
      this.$refs.ruleForm.clearValidate('img'); // 只清除 'url' 字段的验证
    },
    async uploadPictureVideo() {
      // 确保 VideoBlob 不为空，即用户已经选择了视频文件
      if (this.VideoBlob) {
        this.videoForm = {
          ...this.videoForm, // 保留原有的其他属性
          file: this.VideoBlob,
          comIsShow: true
        };
        return;
      }
      // 远程视频 URL，应该替换为实际的视频文件地址
      //   const remoteUrl = 'https://qiniucloud.jobshaigui.com/2021.mp4';
        const remoteUrl = this.ruleForm.url[0]?.url;
        if (!remoteUrl) {
          console.error('没有提供有效的视频 URL');
          return;
        }
        try {
          // 下载远程视频并转换为 Blob 对象
          const response = await fetch(remoteUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();

          // 根据文件扩展名确定 MIME 类型
          const mimeType = this.getMimeTypeFromUrl(remoteUrl);

          // 创建 File 对象
          const fileExtension = this.getFileExtension(remoteUrl);
          const fileName = `video.${fileExtension}`;
          const file = new File([blob], fileName, { type: mimeType });

          // 使用临时 URL 和 File 对象更新 videoForm
          this.videoForm = {
            ...this.videoForm, // 保留原有的其他属性
            videoUrl: URL.createObjectURL(blob),
            file: file,
            comIsShow: true
          };
          console.log('成功创建 File 对象:', file);
        } catch (error) {
          this.$message.error('该视频路径不允许访问');
          console.error('下载或处理视频时出错:', error);
        }
    },
    // 根据 URL 获取文件扩展名
    getFileExtension(url) {
      const parts = url.split('.');
      return parts[parts.length - 1].toLowerCase();
    },
    // 根据文件扩展名获取 MIME 类型
    getMimeTypeFromUrl(url) {
      const extension = this.getFileExtension(url);
      switch (extension) {
        case 'mp4':
          return 'video/mp4';
        case 'flv':
          return 'video/x-flv';
          // 添加其他可能的视频格式及其 MIME 类型
        default:
          return 'application/octet-stream'; // 默认类型
      }
    },
    //图片上传成功后
    async handleUploadSuccess(response, file, fileList) {
      if (response && response.obj.data.url) {
        const newFile = {
          name: file.name,
          url: response.obj.data.url,
          size: file.size,
        };
        //等渲染完成之后再赋值，否则可能会因为还未渲染而导致找不到对应的DOM元素导致报错
        this.$nextTick(() => {
          this.uploadComplete = true;
          this.uploadInProgress = false;
          this.VideoBlob=false;
          this.ruleForm.url = [newFile];
        });
      } else {
        this.$message.error('Invalid server response format');
      }
    },
  handleUploadError(error, file, fileList) {
    console.error('上传失败:', error);
    this.$message.error('文件上传失败，请重试');
    this.uploadInProgress = false;
  },
    async submitForm(formName) {
      try {
        const valid = await this.$refs[formName].validate();
        if (valid) {
          // 确保视频上传完成后再进行下一步
          if (this.VideoBlob) {
            console.log('手动触发上传');
            await this.submitUpload();
            console.log('上传完成');
          }
          // 如果封面是 Blob URL，则上传图片
          if (typeof this.ruleForm.img === 'string' && this.ruleForm.img.startsWith('blob:')) {
            await this.uploadImage();
          }

          await this.submitVideoData();

        } else {
          this.scrollToFirstError(formName);
        }
      } catch (error) {
        console.error('提交过程中出错:', error);
      }
    },
    async submitUpload() {
      return new Promise((resolve, reject) => {
        this.uploadInProgress = true;
        this.uploadComplete = false;
        // 手动触发上传
        this.$refs.upload.submit();

        // 使用定时器检查上传状态
        const intervalId = setInterval(() => {
          if (this.uploadComplete || !this.uploadInProgress) {
            clearInterval(intervalId);
            if (this.uploadComplete) {
              resolve();
            } else {
              reject(new Error('上传失败'));
            }
          }
        }, 100); // 每100毫秒检查一次上传状态

      });
    },
    async uploadImage() {
      try {
        const response = await fetch(this.ruleForm.img);
        const blob = await response.blob();

        let formData = new FormData();
        formData.append('file', blob, this.$getCookie('cp_name') + '_video_img.png');

        const response3 = await api.ajaxbaseUrl.post('/api/upload/a', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.ruleForm.img = response3.data.obj.data.url;
        console.log('图片上传成功:', response3.data.obj.data.url);
      } catch (error) {
        console.error('图片上传失败:', error);
        throw error; // 抛出错误以停止后续操作
      }
    },
    async submitVideoData() {
      try {
        const response = await api.ajaxbaseUrl.post('/api/Video/Au', this.ruleForm, {
          headers: {'Accept-Language': this.MY_CONSTANT.setApiLang}
        });
        this.$message.success({
          message: response.data.msg,
          offset: 400,
          duration: 3000
        });
        // 提交成功后，跳转到用户视频列表页面
        this.$router.push('/'+this.MY_CONSTANT.setApiLang+'/UserVideoDo')
      } catch (error) {
        console.error('视频数据提交失败:', error);
        throw error; // 抛出错误以停止后续操作
      }
    },
    scrollToFirstError(formName) {
      this.$nextTick(() => {
        const isError = this.$refs[formName].$el.getElementsByClassName('is-error');
        if (isError.length > 0) {
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      });
    }
  },
};
</script>

<style scoped>
/deep/ .quantity .el-input__wrapper {
  width: 50px;

}
</style>
